<template>
  <div class="theme-dashboard-view">
    <!-- Dashboard Title -->
    <h1>{{ $t("inventory_title") }}</h1>

    <!-- Search Menu -->
    <div class="my-3 p-3 shadow-sm border d-flex justify-content-between">
      <div class="d-flex w-100">
        <span class="mx-2 d-flex align-items-center col-2">
          <!-- <i class="fa fa-filter" aria-hidden="true"></i>
						<span class="mx-2" style="width: 15vh">{{
							$t("inventory_search_bar_filter_button")
						}}</span> -->
        </span>

        <div class="col-10 d-flex align-items-center px-2">
          <div class="col-md-8 col-sm-12 border d-flex">
            <input
              type="search"
              :placeholder="$t('inventory_search_bar_placeholder')"
              style="border: none"
              class="p-2 w-100"
              v-model="searchQuery"
              @keydown.enter="SearchProduct"
            />
            <select
              class="form-group"
              style="border: none"
              v-model="searchType"
            >
              <option value="product">
                {{ $t("option_value_search_product") }}
              </option>
              <option value="category">
                {{ $t("option_value_search_category") }}
              </option>
            </select>
            <button
              class="btn border theme-background-primary text-light p-2"
              @click="SearchProduct"
            >
              {{ $t("inventory_search_bar_button_name") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Inidicator -->
    <div class="d-flex justify-content-center">
      <LoadingIndicator :active="loading" />
    </div>
    <!-- Loading Error -->
    <div v-show="load_error">
      <div class="justify-content-center text-center text-danger my-4">
        <h4>{{ load_error }}</h4>
      </div>
    </div>

    <!-- Inventory Table -->
    <div class="my-3" v-show="!loading">
      <Table
        :rows="inventory_data"
        :hover="true"
        :contain="['id', 'name', 'quantity', 'price']"
        :rowClickFunction="clickProduct"
      >
        <template v-slot:id-title>{{
          $t("inventory_table_product_id")
        }}</template>
        <template v-slot:name-title>{{
          $t("inventory_table_product_name")
        }}</template>
        <template v-slot:quantity-title>{{
          $t("inventory_table_product_quantity")
        }}</template>
        <template v-slot:price-title>{{
          $t("inventory_table_product_price")
        }}</template>
      </Table>
    </div>

    <!-- Page navigator -->
    <div class="d-flex justify-content-center" v-if="!loading">
      <!-- {{ $t("page_navigator_name") }}:
			<div
				class="mx-3"
				v-for="page in pages"
				:key="page"
				:class="page == current_page ? 'font-weight-bold' : ''"
				style="cursor: pointer"
				@click="setInventory(page)"
			>
				{{ page }}
			</div>
			<div style="cursor: pointer" @click="nextPage">
				<i class="fa fa-arrow-right" aria-hidden="true"></i>
			</div> -->
      <PageNav
        :pages="pages"
        :current_page="current_page"
        @click-page="setInventory"
        @next-page="nextPage"
        @prev-page="prevPage"
        @goto-first="gotoFirstPage"
        @goto-last="gotoLastPage"
      />
    </div>
  </div>
</template>

<script>
// Components

import Table from "@/components/Table.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import PageNav from "@/components/PageNav.vue";

// Services
import ProductService from "@/services/products.js";

export default {
  name: "InventoryHome",
  components: {
    Table,
    LoadingIndicator,
    PageNav,
  },

  data() {
    return {
      // General Parameters
      loading: false,
      load_error: "",

      // Inventory Parameters
      inventory_data: [],
      current_page: 1,
      pages: 1,

      // Search Parameters
      searchQuery: "",
      searchType: "product",
    };
  },
  methods: {
    clickProduct(index) {
      this.$router.push("/inventory/product/" + this.inventory_data[index].id);
    },
    // Page Navigation
    nextPage() {
      if (this.current_page !== this.pages) {
        this.setInventory(this.current_page + 1);
      }
    },
    prevPage() {
      if (this.current_page !== 1) {
        this.setInventory(this.current_page - 1);
      }
    },
    gotoFirstPage() {
      if (this.current_page !== 1) {
        this.setInventory(1);
      }
    },
    gotoLastPage() {
      if (this.current_page !== this.pages) {
        this.setInventory(this.pages);
      }
    },

    // Get the Inventory Data
    setInventory(page) {
      // Set Loadint indicator to running
      // Empty errror text
      this.loading = true;
      this.load_error = "";

      ProductService.getProducts(page).then(
        (response) => {
          this.loading = false;
          this.current_page = page;
          console.log("PRODUCT:", response.data.data);
          this.inventory_data = response.data.data.products.rows;
          this.pages = response.data.data.total_pages;
        },
        (error) => {
          this.loading = false;
          console.log("Error:", error);
          this.load_error =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },

    SearchProduct() {
      if(this.searchQuery.length === 0) {
        alert("Input a Search Query")
      } else {
        this.$router.push({
          name: "SearchProduct",
          params: {
            query_text: this.searchQuery,
            query_type: this.searchType,
          },
        });
      }
    },
  },
  mounted() {
    // Get Inventory products from backend
    this.setInventory(this.current_page);
  },
};
</script>
